<template>
    <div style="margin-top: 10%">
        <img style="width: 200px;" class="d-block m-auto" src="../assets/logo.png" alt="">
        <p class="text-center mt-2 text-sm text-muted">Nền tảng kết nối đến nhà tuyển dụng dành cho Ứng viên</p>
        <div class="text-center mt-4 font-weight-light">
            <div>
                <p class="mb-5 text-sm">Xin chào {{ user.fullname }}! Có vẻ như bạn đã bị <span class="text-danger">khóa</span>. Xin vui lòng liên hệ TopCV để biết thêm chi tiết.</p>
                <a class="btn-rounded btn btn-sm btn-success" href="https://topcv.vn">Về trang chủ</a>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .btn-success {
        background: #6fbe44;
        border-radius: 25px;
        border: 1px solid #6fbe44;
        padding: 7px 15px;
    }
    .text-sm {
        font-size: 14px;
    }
</style>
<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {

        }
    },
    computed: {
        ...mapState('auth', {
            user: state => state.user,
            isLogined: state => state.isLogined
        }),
    },
}
</script>